import React from "react"

const Form = () => {
  return (
    <div
      className="mt-10 flex flex-row"
      data-sal="slide-down"
      data-sal-delay="150"
      data-sal-easing="ease"
      data-sal-duration="2000"
    >
      <form
        action="/thank-you"
        method="POST"
        className="flex-auto md:w-2/3 md:max-w-[600px]"
        name="Contact"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="Contact" />
        <fieldset className="flex flex-col">
          <legend className="hide-legend">Contact me</legend>
          <div className="mt-2 flex flex-row">
            <div className="mr-1 w-full">
              <input
                className="w-full py-2.5 pl-3 mt-1 rounded-sm bg-darkGray font-[500] text-lightGray text-xs tracking-widest"
                type="text"
                name="name"
                placeholder="Full Name"
                aria-label="Full Name"
                required
              />
            </div>
            <div className="ml-1 w-full">
              <input
                className="w-full py-2.5 pl-3 mt-1 rounded-sm bg-darkGray font-[500]  text-lightGray text-xs tracking-widest"
                type="email"
                name="email"
                placeholder="Email"
                aria-label="Email"
                required
              />
            </div>
          </div>
          <div className="mt-2">
            <input
              className="w-full py-2.5 pl-3 mt-1 rounded-sm bg-darkGray font-[500]  text-lightGray text-xs tracking-widest"
              type="text"
              name="subject"
              placeholder="Subject"
              aria-label="Email Subject"
            />
          </div>
          <div className="mt-2">
            <textarea
              className="w-full h-40 py-2.5 pl-3 mt-1 rounded-sm bg-darkGray font-[500] text-lightGray text-xs tracking-widest"
              name="message"
              placeholder="Your Message"
              aria-label="Email Message Body"
            ></textarea>
          </div>
        </fieldset>
        <div className="flex justify-start">
          <input
            className="btn-gradient-trans inline-blocks text-xs md:text-sm tracking-widest font-[500] text-center text-offWhite uppercase mt-4 px-0 md:px-4 lg:px-6 py-2.5 max-w-full w-full sm:w-32 md:w-36 hover:text-baseDark cursor-pointer"
            type="submit"
            name="submit"
            value="Send it"
          />
        </div>
      </form>
      <div className="flex-auto md:w-1/3 hidden md:flex"></div>
    </div>
  )
}

export default Form

import React from "react"

const BackgroundImage = ({ imageName, bigger, test }) => {
  return (
    <div className="relative">
      <img
        src={imageName}
        alt=""
        className={`absolute z-0 md:block hidden ${
          bigger
            ? "bottom-[-18rem] lg:bottom-[-28rem] 2xl:bottom-[-18rem] right-[-14rem] lg:right-[-16rem] 2xl:right-[-8rem] md:max-w-max md:w-full"
            : "bottom-[-6rem] right-[-8rem] max-w-sm w-full sm:max-w-lg md:max-w-xl lg:max-w-2xl xl:max-w-3xl 2xl:max-w-4xl"
        }`}
      />
    </div>
  )
}

export default BackgroundImage

import React from "react"
import GridWrapper from "../../components/GridWrapper"
import Headline from "../../components/Headline"
import SubText from "../../components/SubText"
import BackgroundImage from "../../components/BackgroundImage"
import Form from "../../components/Form"

// images
import waves from "../../images/waves-triangles.svg"

const Contact = () => {
  return (
    <div className="relative overflow-hidden">
      <GridWrapper>
        <div className="md:block hidden"></div>
        <section
          id="contact"
          className="flex flex-col justify-start max-w-full pb-14 sm:pb-20 md:pb-38 4 pt-14 sm:pt-20 md:pt-36 2xl:pt-48 text-offWhite"
        >
          <div className="z-10">
            <h2
              className="uppercase mb-2 md:mb-6 text-lightGray text-xs tracking-[0.2em] font-[500]"
              data-sal="slide-down"
              data-sal-delay="150"
              data-sal-easing="ease"
              data-sal-duration="2000"
            >
              Drop me a line
            </h2>
            <Headline title="I would love" subtitle="to hear from you." />
            <SubText
              subText="I'm currently open to hearing about new opportunities, so feel free to leave your information below, or get right to the punch by contacting me directly at "
              externalLink="mailto:charlie.billadeau@gmail.com"
              linkName="charlie.billadeau@gmail.com."
            />
            <Form />
          </div>
        </section>
        <div className="md:block hidden"></div>
      </GridWrapper>
      <BackgroundImage imageName={waves} bigger />
    </div>
  )
}

export default Contact

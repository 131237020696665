import * as React from "react"

import Layout from "../components/layout"
import Hero from "../sections/Hero"
import Portfolio from "../sections/Portfolio"
import About from "../sections/About"
import Seo from "../components/seo"
import Contact from "../sections/Contact"
import Experience from "../sections/Experience"
// import Sprinkles from "../components/Sprinkles"

const IndexPage = () => (
  <Layout>
    <Seo title="Charlie Billadeau" />
    {/* <Sprinkles variantTop /> */}
    <Hero />
    <Portfolio />
    <Experience />
    {/* <Sprinkles variantBottom /> */}
    <About />
    <Contact />
  </Layout>
)

export default IndexPage

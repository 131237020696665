import React from "react"
import { Link } from "gatsby"

import GridWrapper from "../../components/GridWrapper"
import Headline from "../../components/Headline"
import SubText from "../../components/SubText"
import BackgroundImage from "../../components/BackgroundImage"

// images
import wavesShapes from "../../images/waves-shapes.svg"

const Hero = () => {
  return (
    <div className="relative overflow-hidden">
      <GridWrapper>
        <div className="md:block hidden"></div>
        <section
          id="#"
          className="flex flex-col justify-start mt-[110px] py-14 sm:py-20 md:py-36 2xl:py-48 max-w-full text-offWhite"
        >
          <div className="z-10">
            <header>
              <Headline
                firstHeadline
                title="Frontend software developer"
                highlightedSpan="engineering"
                subtitle="beautiful digital experiences."
              />
            </header>
            <SubText
              subText="I focus on bridging technical expertise with innovative designs, engineered for humans. In short, I make new things, test them to their breaking points, and then build them back better. If you're interested, "
              externalLink="#contact"
              linkName="let's chat."
            />

            <div
              className="mt-6 md:mt-10"
              data-sal="slide-down"
              data-sal-delay="150"
              data-sal-easing="ease"
              data-sal-duration="2000"
            >
              <Link
                to="/#portfolio"
                className="btn-gradient-trans inline-block text-[12px] md:text-xs lg:text-sm tracking-widest font-[500] text-center text-offWhite uppercase px-4 lg:px-6 py-1.5 md:py-2.5 max-w-full md:w-48  hover:text-baseDark"
              >
                View Portfolio
              </Link>
            </div>
          </div>
        </section>
        <div className="md:block hidden"></div>
      </GridWrapper>
      <BackgroundImage imageName={wavesShapes} />
    </div>
  )
}

export default Hero

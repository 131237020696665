import { Link } from "gatsby"
import React from "react"
import GridWrapper from "../../components/GridWrapper"
import Headline from "../../components/Headline"

const Portfolio = () => {
  return (
    <GridWrapper>
      <div className="md:block hidden"></div>
      <section
        id="portfolio"
        className="flex flex-col justify-start max-w-full sm:max-w-xl md:max-w-3xl lg:max-w-4xl py-14 sm:py-20 md:py-36 2xl:py-48 text-offWhite"
      >
        <h2
          className="uppercase mb-2 md:mb-6 text-accentYellow text-xs tracking-[0.2em] font-[500]"
          data-sal="slide-down"
          data-sal-delay="150"
          data-sal-easing="ease"
          data-sal-duration="2000"
        >
          <span className="text-lg">🚧 </span>Under Construction
          <span className="text-lg"> 🚧</span>
        </h2>
        <Headline
          title="Check out"
          subtitle="some of my"
          titleTwo="latest"
          subtitleTwo="work."
        />
        <div
          className="text-sm md:text-base leading-6 max-w-full sm:max-w-md md:max-w-lg lg:max-w-xl text-offWhite mt-10 md:mt-12"
          data-sal="slide-down"
          data-sal-delay="150"
          data-sal-easing="ease"
          data-sal-duration="2000"
        >
          <p>
            I'm currently making this section bigger and better in order to
            showcase a bunch of new, exciting stuff. For now, you can check out
            my{" "}
            <a
              className="text-accentYellow underline underline-offset-2 decoration-solid hover:text-accentBlue transition ease-in-out duration-150"
              href="https://github.com/crbilladeau"
              target="_blank"
              rel="noreferrer"
            >
              Github
            </a>{" "}
            for links to live versions of some of my personal projects, or you
            can quickly view a few of my more current professional works below.
          </p>
        </div>

        <div
          className="flex flex-col justify-start mt-8"
          data-sal="slide-down"
          data-sal-delay="150"
          data-sal-easing="ease"
          data-sal-duration="2000"
        >
          <h4 className="uppercase mb-3 md:mb-4 text-accentYellow text-xs tracking-[0.2em] font-[500]">
            Latest Professional Work @Timberwolves
          </h4>
          <ul className="text-sm grid grid-cols-1">
            <li className="py-1">
              <Link
                href="https://www.sylsfinalride.com"
                className="text-offWhite hover:text-accentYellow transition duration-150 ease-in-out hover:underline"
              >
                Syl's Final Ride - A Tribute Page to WNBA Legend Sylvia Fowles
              </Link>
            </li>
            <li className="py-1">
              <Link
                href="https://www.nba.com/timberwolves/remix"
                className="text-offWhite hover:text-accentYellow transition duration-150 ease-in-out hover:underline"
              >
                2021-22 Timberwolves City Edition MicroSite
              </Link>
            </li>
            <li className="py-1">
              <Link
                href="https://www.nba.com/timberwolves/groups"
                className="text-offWhite hover:text-accentYellow transition duration-150 ease-in-out hover:underline"
              >
                Timberwolves Group Tickets
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <div className="md:block hidden"></div>
    </GridWrapper>
  )
}

export default Portfolio
